/*
 * Code syntax highlighting from https://github.com/jwarby/jekyll-pygments-themes
 */
@import "native.css";


/*
 * Variable definitions
 */

$font-stack-tt: 'Coustard', sans-serif;

$splash-color-1: $primary;
$splash-color-2: $success;
$splash-color-3: $info;
$splash-color-4: $secondary;


/*
 * General page layout
 */

.std-page-padding {
  padding-left: ($spacer * 2) !important;
  padding-right: ($spacer * 2) !important;
}
@include media-breakpoint-up(lg) { // navbar also expands at lg
  .std-page-padding {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}

.page-style-std {
  padding-left: ($spacer * 2) !important;
  padding-right: ($spacer * 2) !important;
  padding-top: 65px;
}
@include media-breakpoint-up(lg) {
  .page-style-std {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}

.page-style-nocontainer {
  padding-top: 55px;
}

.main-with-sidebar {
  padding-left: ($spacer * 1);
  padding-right: ($spacer * 1);
  padding-top: ($spacer * 1);
  padding-bottom: ($spacer * 1);
}
@include media-breakpoint-up(md) {
  .main-with-sidebar {
    padding-left: ($spacer * 4);
    padding-right: ($spacer * 4);
  }
}
@include media-breakpoint-up(lg) {
  .main-with-sidebar {
    padding-left: ($spacer * 4);
    padding-right: ($spacer * 6);
  }
}

h1 {
  margin-top: 0.5rem;
}

.text-highlight-div {
  color: $white;
  background-color: $primary;
}

.text-highlight-span {
  color: $white;
  background-color: $primary;
  padding: ($spacer * 0.3);
}
.text-highlight-span a {
  color: $white;
}
.text-highlight-span a:hover {
  color: $gray-200;
  text-decoration: none;
}

.list-in-text {
  padding-inline-start: 1em;
}
@include media-breakpoint-up(md) {
  .list-in-text {
    padding-inline-start: 2em;
  }
}
.list-in-text > li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.list-bold > li {
  font-weight: bold;
}

.container-text-box {
  @extend .container;
  @extend .bg-light;
  border-radius: 1rem;
  margin-top: ($spacer * 0.5);
  margin-bottom: ($spacer * 0.5);
  padding-top: ($spacer * 1.0);
  padding-bottom: ($spacer * 1.0);
  padding-left: ($spacer * 1.5);
  padding-right: ($spacer * 1.5);
}
@include media-breakpoint-up(md) {
  .container-text-box {
    margin-top: ($spacer * 1.0);
    margin-bottom: ($spacer * 1.0);
    padding-top: ($spacer * 1.75);
    padding-bottom: ($spacer * 1.5);
    padding-left: ($spacer * 3.0);
    padding-right: ($spacer * 3.0);
    max-width: 2000px;
  }
}


/* Correct positioning of anchors coping with fixed navbar at top of page
 * Thanks to: https://www.caktusgroup.com/blog/2017/10/23/css-tip-fixed-headers-and-section-anchors/
 */
.anchor {
  position: relative;
}
.anchor > a {
  position: absolute;
  left: 0px;
  top: -3.5rem;
}


/*
 * Front page styling
 */

.jumbotron {
  padding-top: 32px;
  padding-bottom: 24px;
}

.tt-text-big {
  font-family: $font-stack-tt;
  font-size: 52px;
  font-weight: 500;
}

.tt-text-subtitle {
  font-weight: 600;
}


/*
 * Card styling (for card.html include)
 */
.card-body {
  font-size: 0.95rem;
}

.card-body .btn {
  font-size: 0.95rem;
}


/*
 * Main navbar
 */

// minor restylying of Google custom search button
.navbar-searchbox {
  button {
    padding: 8px 27px 9px 27px !important;
    border-radius: 4px !important;
  }
}


/*
 * Footer
 */

.footer {
  @extend .clearfix;
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: $secondary;
}

.footer a:visited, .footer a:link, .footer a:active {
  color: $secondary;
}

.footer a:hover {
  color: $primary;
  text-decoration: none;
}

.footer-copyright {
  font-size: 0.9em;
  line-height: 1.3;
  color: $navbar-light-color;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.footer-colophon {
  font-size: 0.9em;
}

.social-icons {
  display: inline;
  @extend .ml-1;
  @extend .pl-1;
}

.social-icons li {
  display: inline;
  @extend .mx-1;
}


/*
 * Images
 */

 .img-border {
  @extend .shadow;
  padding: 1em 1em;
  border-radius: 0.3em;
  border-color: $gray-400;
  border-width: 1px;
  border-style: solid;
}

 .img-float-right-25,
 .img-float-right-50,
 .img-float-right-60 {
  @extend .float-md-right;
  @extend .text-center;
  @extend .mb-3;
  @extend .mt-3;
  margin-left: 12.5%;
  width: 75%;
}
.img-float-right-25 img,
.img-float-right-50 img,
.img-float-right-60 img {
  @extend .rounded;
  width: 100%;
}
@include media-breakpoint-up(md) {
  .img-float-right-25 {
    margin-left: $spacer;
    width: 25%;
  }
  .img-float-right-50 {
    margin-left: $spacer;
    width: 50%;
  }
  .img-float-right-60 {
    margin-left: $spacer;
    width: 60%;
  }
}


/*
 * Sidebar
 * Based upon https://getbootstrap.com/docs/4.4/examples/dashboard/dashboard.css
 */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 2rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: $secondary;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: $light;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  background-color: $light;
  color: $secondary;
  font-weight: bold;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}


/*
 * Publication list
 */
.publication-list ol {
   padding-inline-start: 0px;
 }
.publication-list ol li {
  list-style-type: none;
}
.overflow-scroll {
  overflow: auto;
}
.btn-bib {
  font-size: 0.75rem;
  margin: 0.3rem 0.1rem;
  padding: 0.2rem 0.3rem;
}
.bib-details {
  display: inline;
}
.related-papers {
  font-size: 0.9em;
}

.pubnav {
  @extend .text-center;
  margin-top: $spacer;
  margin-bottom: $spacer;
  font-size: 0.8rem;
}
.pubnavitem {
  display: inline-block;
  margin: 0.15rem 0.05rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  border-radius: 0.2rem;
}
.pubnavitem {
  background-color: $light !important;
}
.pubnavitem a {
  color: $navbar-light-color;
}
.pubnavitem a:hover {
  color: $navbar-light-hover-color !important;
  text-decoration: none !important;
}

.micronav {
  font-size: 0.6em;
}
.micronav i,
.micronav a {
  color: $secondary !important;
}
.micronav i:hover,
.micronav a:hover {
  color: $primary !important;
}


/*
 * Book page
 */
.book-page-section-first {
  margin-top: ($spacer * 2.0);
}

.book-page-section {
  margin-top: ($spacer * 1.4);
}

.book-page-section blockquote {
  @extend .m-3;
  @extend .shadow;
  font-size: 1rem;
  padding: 1em 1em;
  border-radius: 0.3em;
  border-color: $gray-400;
  border-width: 2px;
  border-style: solid;
}

.book-page-section .blockquote-footer {
  @extend .mt-2;
  font-size: 95%;
}

.section-separator {
  @extend .text-center;
  @extend .text-muted;
  margin-top: ($spacer * 1.75);
  font-size: 0.65em;
}

.booknav {
  @extend .text-center;
  margin-top: $spacer;
  margin-bottom: $spacer;
  font-size: 1.1rem;
}
.booknavitem {
  display: inline-block;
  margin: 0.15rem 0.05rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  border-radius: 0.2rem;
}
.booknavitem a:not(:hover) {
  text-decoration-line: underline;
  text-decoration-style: dotted;
}


/*
 * Blog
 */

 .blog-post-container {
   @extend .mx-auto;
   max-width: 950px;
   background-color: white;
 }

 .blog-index-post-background {
   background-color: $light;
   padding: 1em 2em;
   border-radius: 0.2em;
 }

.blog-post-card {
  @extend .my-4;
  @extend .mx-2;
}

.blog-post-card card-footer {
  font-size: 0.9em;
}

.blog-metadata {
  @extend .text-muted;
  font-size: 0.95em;
  margin-bottom: 0.5em;
}

.blog-tags {
  font-size: 0.95em;
}

.blog-tag {
  @extend .bg-light;
  display: inline;
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-top: 0.1em;
  padding-bottom: 0.2em;
  border-radius: 0.2em;
  color: $navbar-light-color !important;
}

.blog-tag:hover {
  color: $navbar-light-hover-color !important;
}

.blog-tag a {
  color: $navbar-light-color !important;
}

.blog-tag a:hover {
  color: $navbar-light-hover-color !important;
  text-decoration: none !important;
}


/*
 * Bootstrap-style callout boxes
 * from https://codepen.io/superjaberwocky/pen/AXdEWj
 */
.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}

@each $name,$color in (default,$primary),(primary,$primary),(success,$success),(danger,$danger),(warning,$warning),(info,$info), (light,$light), (dark,$dark),
   (splash-1,$splash-color-1),(splash-2,$splash-color-2),(splash-3,$splash-color-3),(splash-4,$splash-color-4) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}

@each $name,$color in (splash-1,$splash-color-1),(splash-2,$splash-color-2),(splash-3,$splash-color-3),(splash-4,$splash-color-4) {
  .#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
}
